import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowModule from '@/store/modules/workflowModule';
import { DateTime } from 'luxon';

import { getConfigEnv } from '@/utils/helpers';

const operator = getConfigEnv('OPERATOR_LOWERCASED')

const mapDefaultProducingTasq = (t, userEmail = ''): TasqJob => {
  let wellName = t.wellName != null ? t.wellName : t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID;
  if (t.wellName != null) {
    wellName = t.wellName;
  } else {
    wellName = t.NodeID;
    if (t.NodeID.wellName != null) {
      wellName = t.NodeID.wellName;
    }
  }

  let  alarmByWellName =  null
  if(operator === 'pdc'){
     alarmByWellName =   (workflowModule.alarmByWellName(t.wellName))
  }else{
     alarmByWellName =   (workflowModule.alarmByWellName(wellName))
  }


  if(alarmByWellName){
    t.AlarmPayload =  alarmByWellName
  }


  //snoozed end date is 3 days back from now then remove snooze
  if (t.Snoozed && t.Snoozed.UntilDate) {
    // @ts-ignore
    const endDate = DateTime.fromISO(t.Snoozed.end_date);
    let daysDiff: any = DateTime.local().diff(endDate, 'days').toObject()?.days?.toFixed();
    if (daysDiff > 3) {
      t.Snoozed = null;
    }
  }

  const tasq = {
    id:  t.NodeID || t.wellName,
    scheduledJobID: t.ID != null ? t.ID : null,
    predictionType: 'Producing',
    engineerType: 'Producing',
    wellName,
    padName: t.PadName,
    area: t.Area,
    route: t.Route,
    comments: t.comments,
    wells: [],
    team: t.Team,
    // eslint-disable-next-line no-nested-ternary
    level: t.Level != null ? t.Level : t.level != null ? t.level : 'WELL',
    snoozed: t.Snoozed,
    activeTodolistForUser: t.activeTodolistForUser || false,
    activeTodolistCount: t.activeTodolistCount || 0,
    isAlarmActive: t.AlarmPayload && t.AlarmPayload.alarm,
    isShutdownAlarmActive: t.AlarmPayload && t.AlarmPayload.shutdown,
    alarmType: t.AlarmPayload && t.AlarmPayload.alarm_type ? t.AlarmPayload.alarm_type : null,
    alarmTime: t.AlarmPayload && t.AlarmPayload.time ? t.AlarmPayload.time : null,
    waitUntilReason: t.Snoozed ? t.Snoozed.body : '',
    isSnoozedForToday: getSnoozedForTodayStatus(t),
    waitingOnEndDate: t.Snoozed ? t.Snoozed.end_date : '',
    waitingOnStartDate: t.Snoozed ? t.Snoozed.start_date : '',

  };

  // @ts-ignore
  return tasq;
};


const getSnoozedForTodayStatus = (tasq: any) => {
  if (tasq.Snoozed && tasq.Snoozed.WaitUntilReason && tasq.Snoozed.WaitUntilReason.includes('Snoozed') && tasq.Snoozed.UntilDate && new Date(tasq.Snoozed.UntilDate).getTime() > new Date().getTime()) {
    return true;
  }

  return false;
};



export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultProducingTasq,
};
