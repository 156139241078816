
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import {
  CHART_CLICK_EVENT,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';
import { Debounce } from 'vue-debounce-decorator';
import padSignalsModule from '@/store/modules/padSignalsModule';
import {
  lightningChart,
  //   OnScreenMenuButtonType,
  //   OnScreenMenuButtonShape,
  Themes,
  ColorRGBA,
  ColorHEX,
  SolidFill,
  //   PointShape,
  SolidLine,
  AxisTickStrategies,
  //   LegendBoxBuilders,
  //   AutoCursorModes,
  //   LegendBox,
  //   MouseClickEventType,
  //   MouseEventHandler,
  //   UILUTCheckBox,
  emptyLine,
  //   emptyFill,
  ColorCSS,
  customTheme,
  //   FunnelChartWithLabelsOnSides,
} from '@arction/lcjs';
import { getConfigEnv } from '@/utils/helpers';
// Extract required parts from XYData Generator.
// import {
//   createProgressiveTraceGenerator,
//   createOHLCGenerator,
//   createProgressiveRandomGenerator,
// } from '@arction/xydata';

@Component({})
export default class TasqLightningChart extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: boolean;

  @Prop({ type: String, required: false, default: '' }) activeWellName?: string;

  @Prop({ type: Number, required: false, default: 60 }) activeChartTimes?: number;

  @Prop({ type: String, required: false, default: '' }) filter?: string;

  chart: any = null;

  chartId: any = null;

  chartsLoading = false;

  points = [];

  created() {}

  reloadChartOnScroll() {
    this.chart.engine.layout();
  }
  refresh() {
    this.chart.setTitle(' ');
    this.chart.setTitle('');
  }

  didSelectResetChart() {
    this.chart.getDefaultAxisX().release();
    this.chart.getDefaultAxisY().release();
    if (this.axisY) {
      this.axisY.release();
    }
    if (this.axisY2) {
      this.axisY2.release();
    }
    if (this.axisY3) {
      this.axisY3.release();
    }

    if (this.axisY4) {
      this.axisY4.release();
    }
  }

  get legendSignals(): any {
    const legend_signals: any = [];
    for (let x = 0; x < this.currentSignals.length; x++) {
      legend_signals.push({
        name: this.currentSignals[x].name,
        selected: this.isSignalSelected(this.currentSignals[x].name),
        color: this.currentSignals[x].color,
        type: 'SIGNAL',
      });
    }
    return legend_signals;
  }

  getSignalIndex(signal_name) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal_name.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        return x;
      }
    }
    return -1;
  }

  async updateChartToMatchLegend() {
    for (let a = 0; a < this.legendSignals.length; a++) {
      if (
        this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)] &&
        padSignalsModule[this.selectedSignalType].indexOf(this.legendSignals[a].name) > -1
      ) {
        this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].restore();
      } else if (this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)]) {
        this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].dispose();
      }
    }
  }

  async initializePage(index, signal) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        if (this.chartSeries[x].isDisposed()) {
          this.chartSeries[x].restore();
        } else {
          this.chartSeries[x].dispose();
        }
      }
    }
  }

  async initializeProductionPage(index, signal) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase().includes(check_signal.toLowerCase())) {
        if (this.chartSeries[x].isDisposed()) {
          this.chartSeries[x].restore();
        } else {
          this.chartSeries[x].dispose();
        }
      } else {
        this.chartSeries[x].dispose();
      }
    }
  }

  get signalType() {
    return `${this.filter}Signals`;
  }

  get selectedSignalType() {
    return `${this.filter}SelectedSignals`;
  }

  get currentSignals(): any {
    return padSignalsModule[this.signalType];
  }

  isSignalSelected(signal_name) {
    return padSignalsModule[this.selectedSignalType].indexOf(signal_name) > -1;
  }

  storedLinesSeries: any = [];

  dateOrigin;

  legend;

  axisY;

  axisY2;

  axisY3;

  axisY4;

  signalsAdded: any = [];

  isChartLoaded = false;

  createChart() {
    const themeTextFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeDataSeriesFillStyles = [
      new SolidFill({ color: ColorCSS('#00FFEA') }),
      new SolidFill({ color: ColorCSS('#F21688') }),
      new SolidFill({ color: ColorCSS('#FFFF5D') }),
      new SolidFill({ color: ColorCSS('#FFCD5C') }),
      new SolidFill({ color: ColorCSS('#FFC8A5') }),
      new SolidFill({ color: ColorCSS('#FF94B8') }),
      new SolidFill({ color: ColorCSS('#DB94C6') }),
      new SolidFill({ color: ColorCSS('#A994C6') }),
      new SolidFill({ color: ColorCSS('#94B0C6') }),
      new SolidFill({ color: ColorCSS('#94E2C6') }),
      new SolidFill({ color: ColorCSS('#94FFB0') }),
      new SolidFill({ color: ColorCSS('#94ffdf') }),
      new SolidFill({ color: ColorCSS('#94eaff') }),
      new SolidFill({ color: ColorCSS('#94c1ff') }),
      new SolidFill({ color: ColorCSS('#a894ff') }),
      new SolidFill({ color: ColorCSS('#ffb194') }),
      new SolidFill({ color: ColorCSS('#90e64e') }),
      new SolidFill({ color: ColorCSS('#e64e4e') }),
      new SolidFill({ color: ColorCSS('#b14ee6') }),
      new SolidFill({ color: ColorCSS('#41c459') }),
      new SolidFill({ color: ColorCSS('#41c4b0') }),
    ];

    const themeAxisFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000032') });
    const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000014') });
    const themeUiBackgroundFillStyle = new SolidFill({
      color: ColorCSS('rgba(120,120,120,0.5)'),
    }); // Legend color
    const themeUiBackgroundBorderFillStyle = new SolidFill({
      color: ColorCSS('#2b2b2b'),
    });
    const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    // darkMagenta
    const myTheme = customTheme(Themes.cyberSpace, {
      lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      panelBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      chartTitleFillStyle: themeTextFillStyle,
      axisTitleFillStyle: themeTextFillStyle,
      axisStyle: new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }),
      numericTickStrategy: Themes.lightNew.numericTickStrategy
        .setMajorTickStyle((majorTicks) =>
          majorTicks
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle }))
            .setGridStrokeStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle })
            )
        )
        .setMinorTickStyle((minorTicks) =>
          minorTicks
            // @ts-ignore
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle }))
            .setGridStrokeStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle })
            )
        ),
      seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
      seriesStrokeStyle: (i) =>
        new SolidLine({
          thickness: 2,
          fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
        }),
      uiBackgroundFillStyle: themeUiBackgroundFillStyle,
      uiBackgroundStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiTextFillStyle: themeTextFillStyle,
      resultTableFillStyle: themeUiBackgroundFillStyle,
      resultTableStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      resultTableTextFillStyle: themeTextFillStyle,
      customTickGridStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeCursorGridlineFillStyle,
      }),
      uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
      uiPointableTextBoxStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiPointableTextBoxTextFillStyle: themeTextFillStyle,
      pointMarkerFillStyle: new SolidFill({ color: ColorCSS('#ffffff') }),
      chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYZoomingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
      chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYFittingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
    });

    const license = `0001-ff5ad4e054f80f2dd0977e5194ea2c7804dcae2d8fc19d352bb53ce081d1e1c8cc21be14a8b691660e1133da1d4d73813c2b4d760ddc385f32cd859fc3f6b7ed664894c62e4fa717fea588bb829429c8f7f9218a4f1f6684ca663999e246-2acb5316c96ce6435be3182ea4ee94db-30450220319f968bebc89be5d0ac8cb5b1903e4e023a07525c1b84d8aed5136bb0667a82022100f3ad7a24bde5f7427f10f3c1367aff4708fad5507d937da7e75220d5e29578d9`;
    if (location.href.includes('tasq.io')) {
      this.chart = lightningChart(license)
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    } else {
      this.chart = lightningChart()
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    }

    this.chart.setAnimationsEnabled(false);
    this.chart.setMouseInteractionWheelZoom(false);

    this.axisY = this.chart.getDefaultAxisY();

    if (this.filter === 'aggregate') {
      this.axisY2 = this.chart.addAxisY({
        opposite: true,
      });
      this.axisY3 = this.chart.addAxisY({});
    }

    //   this.axisY3 = this.chart.addAxisY({
    //   })

    //   // Hide tick grid-lines from second Y axis.
    //     .setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
    //       .setMinorTickStyle((minor) => minor
    //         .setGridStrokeStyle(emptyLine))
    //       .setMajorTickStyle((major) => major
    //         .setGridStrokeStyle(emptyLine)));

    // var offset = new Date().getTimezoneOffset();
    const d = new Date(tasqsListModule.chartFromTime);
    // d.setDate(d.getDate()-tasqsListModule.signalChartTime);
    // d.setMinutes(d.getMinutes() - offset);
    this.dateOrigin = d;
    this.chart
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) =>
        tickStrategy.setDateOrigin(this.dateOrigin)
      );
  }

  chartSeries: any = [];

  bandGasSignals: any = [];

  bandOilSignals: any = []

  signalCount = 0;
  addSignalToChart(newSignal) {
    // @ts-ignore

    const resultInMinutes = newSignal.dataset.length > 1000 ? 5 : 60 * 24;
    const productionType = getConfigEnv('PRODUCTION_TYPE');
    const signalData: any = [];

    let previous_non_null_val = 0;
    let encountered_a_non_null = false;
    // var offset = new Date().getTimezoneOffset();

    // var offset = new Date().getTimezoneOffset();
    let axisToUse = this.axisY;
    for (let signalY = 0; signalY < newSignal.dataset.length; signalY++) {
      let val = parseFloat(newSignal.dataset[signalY]);
     let time = signalY * (resultInMinutes * 1000 * 60)
      if(newSignal.time[signalY]){
        time = new Date(newSignal.time[signalY]).getTime() - this.dateOrigin.getTime();
      }

      if (isNaN(val)) {
        if (!encountered_a_non_null) {
          continue;
        }
        val = previous_non_null_val;
      } else {
        encountered_a_non_null = true;
        previous_non_null_val = val;
      }

      // @ts-ignore
      signalData.push({
        // @ts-ignore
        x:time,
        // @ts-ignore
        y: val,
      });
    }

    if (this.filter === 'aggregate') {
      this.axisY.setTitle('Gas Rate');
      if (newSignal.name.includes('Oil Rate Aggregate') || newSignal.name.includes('Water Rate')) {
        axisToUse = this.axisY2;

        this.axisY2.setTitle('Oil Rate & Water Rate');
      }
      if (newSignal.name.includes('Pressure') || newSignal.name.includes('Separator')) {
        axisToUse = this.axisY3;
        this.axisY3.setTitle('Pressures');
      }

      if (
        newSignal.name &&

        (newSignal.name.includes('Lower') ||
        newSignal.name.includes('Upper') ||
        newSignal.name.includes('Target'))
      ) {


        if(  newSignal.name.toLowerCase().includes("oil")){
            this.bandOilSignals.push(newSignal)
            if (this.bandOilSignals.length === 3) {
          const upper = this.bandOilSignals.find((signal) => signal.name.includes('Upper'));
          const lower = this.bandOilSignals.find((signal) => signal.name.includes('Lower'));
          const target = this.bandOilSignals.find((signal) => signal.name.includes('Target'));



          if (upper && lower && target) {
            this.setTargetBand(upper, lower, target, 'oil');
          }
        }
        }
        if(  newSignal.name.toLowerCase().includes("oil")){
            this.bandGasSignals.push(newSignal)
            if (this.bandGasSignals.length === 3) {
          const upper = this.bandGasSignals.find((signal) => signal.name.includes('Upper'));
          const lower = this.bandGasSignals.find((signal) => signal.name.includes('Lower'));
          const target = this.bandGasSignals.find((signal) => signal.name.includes('Target'));



          if (upper && lower && target) {
            this.setTargetBand(upper, lower, target, 'gas');

          }
        }
        }

        return;
      }
    } else if (newSignal.selected && (this.filter === 'pad' || this.filter === 'compressor')) {
      this.signalCount += 1;
      if (this.signalCount === 1) {
        this.axisY.setTitle(newSignal.name);
      } else if (this.signalCount === 2) {
        this.axisY2 = this.chart.addAxisY({
          opposite: true,
        });

        axisToUse = this.axisY2;
        this.axisY2.setTitle(newSignal.name);
      } else if (this.signalCount === 3) {
        // this.axisY3 = this.chart.addAxisY({});

        this.axisY3 = this.chart
          .addAxisY({})

          // Hide tick grid-lines from second Y axis.
          .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
            ticks
              .setMinorTickStyle((minor) => minor.setGridStrokeStyle(emptyLine))
              .setMajorTickStyle((major) => major.setGridStrokeStyle(emptyLine))
          );
        axisToUse = this.axisY3;

        this.axisY3.setTitle(newSignal.name);
      } else if (this.signalCount === 4) {
        this.axisY4 = this.chart
          .addAxisY({ opposite: true })

          // Hide tick grid-lines from second Y axis.
          .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
            ticks
              .setMinorTickStyle((minor) => minor.setGridStrokeStyle(emptyLine))
              .setMajorTickStyle((major) => major.setGridStrokeStyle(emptyLine))
          );
        axisToUse = this.axisY4;

        this.axisY4.setTitle(newSignal.name);
      }
    }

    // Add line series to the chart
    const lineSeriesSignals = this.chart.addLineSeries({
      yAxis: axisToUse,
    });
    this.chartSeries.push(lineSeriesSignals);

    lineSeriesSignals.setStrokeStyle(
      new SolidLine({
        thickness: 1.5,
        fillStyle: new SolidFill({ color: ColorHEX(newSignal.color) }),
      })
    );
    // Set stroke style of the line
    lineSeriesSignals.setStrokeStyle((style) => style.setThickness(1.5));
    lineSeriesSignals.setName(newSignal.name);
    // Add data points to the line series
    lineSeriesSignals.add(signalData);
    //   signalData = []
    if (!this.isSignalSelected(newSignal.name)) {
      lineSeriesSignals.dispose();
    }
  }

  @Watch('isChartLoaded')
  @Watch('currentSignals')
  updateChartSignals() {
    if (this.currentSignals.length - 1 < 0) {
      return;
    }

    if (!this.loadedSignals.length) {
      padSignalsModule.resetSelectedSignals(this.selectedSignalType);
    }

    this.currentSignals.forEach((signal) => {
      if (!this.loadedSignals.includes(signal.name)) {
        this.loadedSignals.push(signal.name);
        if (signal.selected) {
          padSignalsModule.selectSignal({
            signalName: signal.name,
            selectedType: this.selectedSignalType,
          });
        }
        this.addSignalToChart(signal);
      }
    });
  }

  setViewNicely = (areaRange, stockAxisY) => {
    const yBoundsStock = {
      min: areaRange.getYMin(),
      max: areaRange.getYMax(),
      range: areaRange.getYMax() - areaRange.getYMin(),
    };
    // Set Y axis intervals so that series don't overlap and volume is under stocks.
    stockAxisY.setInterval(yBoundsStock.min - yBoundsStock.range * 0.33, yBoundsStock.max);
  };

  setTargetBand(upper, lower, target, type) {
    // Add series.
    let bandColor = ColorRGBA(45, 230, 193, 80);
    let axisY = this.axisY2;
    if (type === 'gas') {
      axisY = this.axisY;
      bandColor = ColorRGBA(245, 93, 139, 80);
    }
    const areaRange = this.chart.addAreaRangeSeries({ yAxis: axisY }).setName('Target band');

    var day_count = 0;
    var check_date = new Date(this.dateOrigin.getTime());
    const prodDataFrequency = 1000 * 60 * 60 * 24;
    var new_signal_data: any = [];

    if (upper.dataset && lower.dataset) {
      for (var d = 0; d < upper.dataset.length; d++) {
        areaRange.add({
          position: (d + day_count) * 24 * 60 * 60 * 1000,
          high: parseFloat(upper.dataset[d]),
          low: parseFloat(lower.dataset[d]),
        });
      }
    }
    this.setViewNicely(areaRange, axisY);

    var new_signal_data: any = [];
    if (target.dataset) {
      for (var d = 0; d < target.dataset.length; d++) {
        // @ts-ignore
        new_signal_data.push({
          // @ts-ignore
          x: (d + day_count) * prodDataFrequency,
          // @ts-ignore
          y: parseFloat(target.dataset[d]),
        });
        // Add line series to the chart
      }
    }
    const lineSeriesSignalsProd = this.chart.addLineSeries({
      yAxis: axisY,
    });

    lineSeriesSignalsProd.setStrokeStyle(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorRGBA(170, 170, 170, 100) }),
      })
    );

    // Set stroke style of the line
    lineSeriesSignalsProd.setStrokeStyle((style) => style.setThickness(2));
    lineSeriesSignalsProd.setName('Target');
    // Add data points to the line series
    lineSeriesSignalsProd.add(new_signal_data);

    // legendProd.add(lineSeriesSignalsProd)
    areaRange.setLowStrokeStyle(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );
    areaRange.setLowStrokeStyleHighlight(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );
    areaRange.setHighStrokeStyle(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );
    areaRange.setHighStrokeStyleHighlight(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );
    areaRange.setHighStrokeStyle(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );
    areaRange.setHighStrokeStyleHighlight(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: bandColor }),
      })
    );

    areaRange.setHighFillStyle(
      new SolidFill({
        color: bandColor,
      })
    );
    areaRange.setLowFillStyle(
      new SolidFill({
        color: bandColor,
      })
    );
  }

  loadedSignals: any = [];

  didAddProductionData: any = {};

  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000);

    //   console.log(this.chartId);
  }

  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    this.createChart();

    if (this.isFullScreen) {
      for (let x = 0; x < this.currentSignals.length; x++) {
        this.addSignalToChart(this.currentSignals[x]);
      }
      this.updateChartToMatchLegend();
    } else {
      this.isChartLoaded = true;
    }
  }

  beforeDestroy() {
    this.chart.dispose();
  }
}
